import React from 'react';
import farol from '../images/farol.jpg';
import lanterna from '../images/lanterna.jpg';
import motor from '../images/motor.jpg';
import painel from '../images/painel.jpg';
import pecas from '../images/pecas.jpg';
import roda from '../images/roda.jpg';

class Produtos extends React.Component {
  render() {
    const imagens = [
      [farol, 'FARÓIS'],
      [lanterna, 'LANTERNAS'],
      [motor, 'MOTORES'],
      [painel, 'PAINÉIS'],
      [pecas, 'PEÇAS'],
      [roda, 'RODAS'],
    ];
    return (
      <div>
        <div
          className="flex flex-col sm:w-[80%] sm:pl-24"
        >
          <p
            className="text-left w-80% pt-10
            text-3xl md:text-3xl lg:text-5xl xl:text-6xl font-custom md:mt-10 pl-4"
          >
            CONHEÇA NOSSOS PRODUTOS
          </p>
          <p
            className="text-2xl md:mb-16 md:mt-10
            mt-4 text-base md:text-lg text-left mb-10 pl-4"
          >
            Somos uma empresa especializada em fornecer peças automotivas
            de alta qualidade, trabalhando com uma ampla gama de peças nacionais
            e importadas. Nossa equipe de profissionais altamente qualificados está
            comprometida em fornecer aos nossos clientes as melhores soluções em
            peças automotivas, desde os modelos mais antigos até os mais recentes
            e modernos.
          </p>
        </div>
        <div
          className="flex flex-wrap w-[99%] justify-center"
        >
          { imagens.map((e, i) => {
            return (
              <div
                key={ i }
                className="w-[45%] sm:w-[33%] flex flex-col items-center"
              >
                <p
                  className="text-3xl md:text-4xl lg:text-5xl xl:text-5xl  font-custom"
                >
                  {e[1]}

                </p>
                <div
                  className="bg-slate-500 m-6 w-[90%]"
                >
                  <img
                    className="w-full h-64 object-cover"
                    src={ e[0] }
                    alt={ e[1] }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Produtos;
