import React from 'react';
import BtnWhatsApp from './BtnWhatsApp';

class Intermedio extends React.Component {
  render() {
    return (
      <div
        id="home"
        className="bg-[url('./images/capa.jpg')] pt-[80px]
        xl:h-[900px] lg:h-[750px] md:h-[650px] sm:h-[500px]
        h-[550px] bg-center bg-cover md:pt-[150px]"
      >
        <div
          className="flex flex-col md:w-[50%] xl:items-center h-full"
        >
          <p
            className="text-left md:w-[500px] pt-10
            text-4xl md:text-4xl lg:text-6xl xl:text-7xl font-custom
            text-white mt-20 pl-4"
          >
            TRABALHAMOS COM PEÇAS NACIONAIS, IMPORTADAS E PNEUS
          </p>
          <p
            className="text-white text-2xl md:w-[500px] md:mb-16 md:mt-10
            mt-4 text-base md:text-lg text-left mb-10 pl-4"
          >
            Possuímos um estoque com mais de 10.000 itens.
            <br />
            Disponibilizando aos nossos clientes a maior variedade,
            qualidade e o melhor preço em
            Auto Peças da região. Entregamos em todo Brasil.
            <br />
            Pneus e peças com os melhores preços!
          </p>
          <div
            className="flex justify-center"
            id="produtos"
          >
            <BtnWhatsApp w="300px" h="50px" />
          </div>
        </div>
      </div>
    );
  }
}

export default Intermedio;
