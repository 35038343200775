import React from 'react';
import Header from '../components/Header';
import Intermedio from '../components/Intermedio';
import Produtos from '../components/Produtos';
import BtnPeqWhatsApp from '../components/BtnPeqWhatsApp';
import Sobre from '../components/Sobre';
import Sucata from '../components/Sucata';

class Home extends React.Component {
  render() {
    return (
      <>
        <BtnPeqWhatsApp />
        <Header />
        <Intermedio />
        <Produtos />
        <Sucata />
        <Sobre />
      </>
    );
  }
}

export default Home;
