import React from 'react';

// className="btn btn-secondary dropdown-toggle"
// type="button"
// data-bs-toggle="dropdown"
// aria-expanded="false"
class List extends React.Component {
  render() {
    return (
      <button>
        <svg
          style={ { color: 'white' } }
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="bi bi-list w-10 md:hidden mr-6"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            // eslint-disable-next-line max-len
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>
    );
  }
}

export default List;
