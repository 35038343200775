import './App.css';
// import TagManager from 'react-gtm-module';
import Home from './pages/Home';

// const tagManagerAds = {
//   gtmId: 'GTM-M34K3K6X',
// };

// TagManager.initialize(tagManagerAds);

function App() {
  // window.dataLayer.push({
  //   event: 'pageview',
  // });
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
