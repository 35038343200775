import React from 'react';
import up from '../images/up_sucata.jpg';
import civic from '../images/civic_sucata.jpg';
import onix from '../images/onix_sucata.jpg';
import BtnWhatsApp from './BtnWhatsApp';

class Sucata extends React.Component {
  render() {
    const sucatas = [
      [up, 'Vw Up Tsi 3cc 2017'],
      [civic, 'Honda civic lxs 1.8 16v 2008 manual'],
      [onix, 'onix plus 1.0 2022 manual'],
    ];
    return (
      <div
        id="sucata"
        className="flex flex-col text-white bg-gray-950 py-20 items-center"
      >
        <p
          className="font-custom text-5xl mb-10 text-left lg:pl-10 w-[80%]"
        >
          Algumas Sucatas Disponíveis
        </p>
        <p
          className="font-thin text-lg mb-10 text-left lg:pl-10 w-[80%]"
        >
          NÃO ENCONTROU O QUE PROCURA? CONSULTE TODO O NOSSO ESTOQUE DE PEÇAS DISPONÍVEL.
        </p>
        <div
          className="hidden md:flex justify-center pt-10 w-full"
        >
          { sucatas.map((e, i) => {
            return (
              <div
                key={ i }
                className="flex flex-col w-[25%] px-6"
              >
                <p
                  className="h-20 text-3xl font-custom"
                >
                  {e[1].toUpperCase()}
                </p>
                <img
                  className="h-[100%] object-cover"
                  src={ e[0] }
                  alt="o"
                />
                <div
                  className="flex justify-center pt-2"
                >
                  <BtnWhatsApp w="300px" h="50px" />
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="flex flex-col md:hidden items-center justify-center pt-10 w-full"
        >
          { sucatas.map((e, i) => {
            return (
              <div
                key={ i }
                className="flex flex-col w-[85%] px-6 my-3"
              >
                <p
                  className="h-20 text-3xl font-custom"
                >
                  {e[1].toUpperCase()}
                </p>
                <img
                  className="h-[100%] object-cover"
                  src={ e[0] }
                  alt="o"
                />
                <div
                  className="flex justify-center pt-2"
                >
                  <BtnWhatsApp w="300px" h="50px" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Sucata;
